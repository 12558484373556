import React, { useEffect, useState } from "react";
import "./ReturnLabel.scss";
import FaceBook from "../../../../assets/images/Facebook_F.svg";
import Twitter from "../../../../assets/images/Twitter_T.svg";
import Insta from "../../../../assets/images/Instagram_I.svg";
import { getMethodWithPlatfrom } from "../../../../utils/services/api";
import { getUserRole } from "../../../../utils/common";
import urls from "../../../../utils/services/apiUrl.json";
import moment from "moment";
import Barcode from "react-barcode/lib/react-barcode";
import Box from "@material-ui/core/Box";
import DefaultImage from "../../../../assets/images/ShopezDefult.svg"

function ReturnLabelSlip({ orderId, storeId,customerDetails }) {
    const [loading, setLoading] = useState(false);
    const [orderReturnSlip, setOrderReturnSlip] = useState({});
  
    const getReturnDetails = async (url, type) => {
        setLoading(true);
        await getMethodWithPlatfrom(url, type).then((response) => {
            if (response.status === 200) {
                setOrderReturnSlip(response.data);
                setLoading(false);
            }
            setLoading(false);
        });
    };

    useEffect(() => {
        if (getUserRole() !== "super-admin") {
            getReturnDetails(urls.ordersMerchant + "?order_id_return_label=" + orderId, "merchant");
        } else {
            getReturnDetails(urls.ordersAdmin + "?stores_id=" + storeId + "&order_id_return_label=" + orderId, "super-admin");
        }
    }, []);

    const getDateFormat = (date) => {
        if (date !== null && date !== "") {
            return moment(date).format("L");
        }
        return;
    };
    const timeFormat = (date) => {
        let requiredFormat = moment(date).format("lll");
        return requiredFormat;
    };

    return (
        <div className="row" style={{ marginLeft: "32px" }}>
            <div className="col-12" >
            <div className="row p-0 mt-4">
                    <div className="h4-label pl-3" style={{ display: "flex", alignItems: "center" }}>
                        Ship to Address Order{" "}
                        {customerDetails && customerDetails?.number}
                    </div>
                    <Box style={{
                        paddingLeft: '15px',
                        paddingRight: '15px',
                        maxWidth: '148px',
                        height: "32px",
                        marginLeft: '20px',
                        display: 'flex',
                        alignItems: "center"
                    }}
                        bgcolor="#00B050" color="white" textAlign="center" borderRadius="20px">
                        {customerDetails?.paymentConfirmation}
                    </Box>
                    <div className="col-md-12 col-lg-12">
                        <div className="shiptext2 mt-3">
                            Received on{" "}
                            {customerDetails && customerDetails.date_placed ? timeFormat(customerDetails.date_placed) : ""}
                            {" "}
                            from{" "}
                            {customerDetails && customerDetails.first_name + " " + customerDetails.last_name}
                        </div>
                    </div>
                </div>
            
                <div className="row mt-4 mr-2">
                    <div className="col-6">
                        <div className="h5-label">Return Label</div>
                        <div className="muiDescripationInfo">   
                            <span><b>Order:</b> </span>
                            {orderReturnSlip && orderReturnSlip.number}
                        </div>
                        
                        <div className="muiDescripationInfo">
                            <span><b>Order Date:</b> </span>
                            {getDateFormat(orderReturnSlip && orderReturnSlip.created_at)}
                        </div>
                    </div>

                    <div className="col-6" style={{textAlign: "right"}}>
                        <img className="listedImg100100" src={orderReturnSlip?.merchant_data?.store_logo?.[0]?.file ? orderReturnSlip?.merchant_data?.store_logo?.[0]?.file : DefaultImage} />
                    </div>
                </div>

                <div className="row mt-4 mr-2">
                    <div className="col-6 billto">
                        <div className="h5-label">Bill to</div>
                        <div className="muiDescripationInfoCheckbox">
                            {orderReturnSlip && orderReturnSlip.billing_address && orderReturnSlip.billing_address.line1 && orderReturnSlip.billing_address.line1}
                            {orderReturnSlip && orderReturnSlip.billing_address && orderReturnSlip.billing_address.line2 && ", " + orderReturnSlip.billing_address.line2}
                            {orderReturnSlip && orderReturnSlip.billing_address && orderReturnSlip.billing_address.line3 && ", " + orderReturnSlip.billing_address.line3}
                            {orderReturnSlip && orderReturnSlip.billing_address && orderReturnSlip.billing_address.line4 && ", " + orderReturnSlip.billing_address.line4}
                            {orderReturnSlip && orderReturnSlip.billing_address && orderReturnSlip.billing_address.state && ", " + orderReturnSlip.billing_address.state}
                            {orderReturnSlip && orderReturnSlip.billing_address && orderReturnSlip.billing_address.postcode && ", " + orderReturnSlip.billing_address.postcode}
                        </div>
                    </div>

                    <div className="col-6">
                        <div className="h5-label">Ship to</div>
                        <div className="muiDescripationInfoCheckbox">
                            {orderReturnSlip && orderReturnSlip.shipping_address && orderReturnSlip.shipping_address.line1 && orderReturnSlip.shipping_address.line1}
                            {orderReturnSlip && orderReturnSlip.shipping_address && orderReturnSlip.shipping_address.line2 && ", " + orderReturnSlip.shipping_address.line2}
                            {orderReturnSlip && orderReturnSlip.shipping_address && orderReturnSlip.shipping_address.line3 && ", " + orderReturnSlip.shipping_address.line3}
                            {orderReturnSlip && orderReturnSlip.shipping_address && orderReturnSlip.shipping_address.line4 && ", " + orderReturnSlip.shipping_address.line4}
                            {orderReturnSlip && orderReturnSlip.shipping_address && orderReturnSlip.shipping_address.state && ", " + orderReturnSlip.shipping_address.state}
                            {orderReturnSlip && orderReturnSlip.shipping_address && orderReturnSlip.shipping_address.postcode && ", " + orderReturnSlip.shipping_address.postcode}
                        </div>
                        <div className="muiDescripationInfoCheckbox">
                            {orderReturnSlip && orderReturnSlip.shipping_address && orderReturnSlip.shipping_address.phone_number}
                        </div>
                    </div>
                </div>

                <div className="row mt-3 mr-2">
                    <div className="col-12">
                        <hr style={{border: "1px solid #C0C0C0", marginTop: "4vw"}}/>
                    </div>
                </div>

                <div className="row mr-2">
                    <div className="col-6 f-600">Item Discription</div>
                    <div className="col-2 f-600">Qty</div>
                    <div className="col-2 f-600">Price</div>
                    <div className="col-2 f-600">Total</div>
                </div>

                <div className="row mt-3 mr-2">
                    <div className="col-12">
                        <hr style={{border: "1px solid #C0C0C0"}}/>
                    </div>
                </div>
                
                {orderReturnSlip && orderReturnSlip.ordered_product !== undefined && orderReturnSlip.ordered_product.length > 0 ? orderReturnSlip.ordered_product.map((item, index) => {
                        return (
                            <div className="row mr-2 pb-3">
                                <div className="col-6 d-flex">
                                    <div className="d-flex">
                                        <img className="listedImg" src={item.product_image[0].original} alt={item.product_name} />
                                    </div>
                                    <div className="ml-2" style={{alignItems: "center"}}>
                                        <div className="h6-label">{item.product_name}</div>
                                        <div className="pDetail"><b style={{textTransform: "capitalize"}}>sku</b> : {item.partner_sku}</div>
                                        {item.attributes.length > 0 &&
                                            (
                                                <>
                                                    {item.attributes.map((attribute, index) => {
                                                        return (
                                                            <>
                                                                {attribute.value !== null && attribute.code !== "color_code_swatch" &&
                                                                    <div className="pDetail" style={{textTransform: "capitalize"}}><b>{attribute.name}</b> : {attribute.value}</div>
                                                                }
                                                            </>
                                                        );
                                                    })}
                                                </>
                                            )
                                        }
                                    </div>
                                </div>
                                <div className="col-2 d-flex" style={{alignItems: "center"}}>
                                    {item.ordered_quantity}
                                </div>
                                <div className="col-2 d-flex" style={{alignItems: "center"}}>
                                    $ {item.total_excl_tax}
                                </div>
                                <div className="col-2 d-flex" style={{alignItems: "center"}}>
                                    $ {item.total_excl_tax}
                                </div>
                            </div>
                        );
                }): ""}

                <div className="row mr-2">
                    <div className="col-12">
                        <hr style={{border: "1px solid #C0C0C0"}}/>
                    </div>
                </div>

                <div className="row mr-2">
                    <div className="col-12">
                        <div><b>Reason for return:</b> {orderReturnSlip && orderReturnSlip.message}</div>
                    </div>
                </div>

                <div className="row ml-2 mr-2">
                    <div className="col-7">
                        {/*<p>
                            <span style={{ color: "orange", paddingLeft: "1.3vw" }}>
                                Note:
                            </span>{" "}
                            This is a gift.
                        </p>*/}
                    </div>
                    {orderReturnSlip?.discount && 
                    <>
                    <div className="col-9 d-flex justify-content-end">
                        <p className="f-600 mr-5">Discount</p>
                    </div>
                    
                    <div className="col-3 d-flex justify-content-center">
                        <p className="ml-2">{orderReturnSlip?.discount}</p>
                    </div>
                    </>
                    }
                </div>
                
                {orderReturnSlip?.discount && 
                <div className="row ml-2 mr-2">
                    <div className="col-6">
                    </div>
                    <div className="col-6">
                        <hr style={{border: "1px solid rgba(83, 83, 83, 0.1)"}}/>
                    </div>
                </div>
                }

                <div className="row ml-2 mr-2">
                    <div className="col-9 d-flex justify-content-end">
                        <div className="f-600 mr-5 "> SubTotal</div>
                    </div>
                    <div className="col-3 d-flex justify-content-center">
                        <p className="ml-2"> ${orderReturnSlip?.sub_total}</p>
                    </div>
                </div>

                <div className="row ml-2 mr-2">
                    <div className="col-6">
                    </div>
                    <div className="col-6">
                        <hr style={{border: "1px solid rgba(83, 83, 83, 0.1)"}}/>
                    </div>
                </div>

                <div className="row ml-2 mr-2">
                    <div className="col-9 d-flex justify-content-end">
                        <div className="f-600 mr-5 "> Shipping</div>
                    </div>
                    <div className="col-3 d-flex justify-content-center">
                        <p className="ml-2"> ${orderReturnSlip?.orderSummary?.[0]?.shippingCharge}</p>
                    </div>
                </div>

                <div className="row ml-2 mr-2">
                    <div className="col-6">
                    </div>
                    <div className="col-6">
                        <hr style={{border: "1px solid rgba(83, 83, 83, 0.1)"}}/>
                    </div>
                </div>

                <div className="row ml-2 mr-2">
                    <div className="col-9 d-flex justify-content-end">
                        <div className="f-600 mr-5 "> Sales Tax</div>
                    </div>
                    <div className="col-3 d-flex justify-content-center">
                        <p className="ml-2"> ${orderReturnSlip?.orderSummary?.[0]?.totalTax}</p>
                    </div>
                </div>

                <div className="row ml-2 mr-2">
                    <div className="col-6">
                    </div>
                    <div className="col-6">
                        <hr style={{border: "1px solid #535353"}}/>
                    </div>
                </div>

                <div className="row ml-2 mr-2">
                    <div className="col-9 d-flex justify-content-end">
                        <div className="f-600 mr-5"> Total</div>
                    </div>
                    <div className="col-3 d-flex justify-content-center">
                        <p className="ml-2"> ${orderReturnSlip?.total_incl_tax}</p>
                    </div>
                </div>

                <div className="row mt-2 mb-5 ml-2 mr-2">
                    <div className="col-12">
                        <div className="h5-label text-center">Thank for your business!</div>
                        <div className="muiDescripationInfo text-center">
                            Please complete the above form & return it with items carefully packed.
                        </div>

                        <div className="mt-2">
                            <div className="h5-label text-center">
                                {orderReturnSlip && orderReturnSlip.merchant_data && orderReturnSlip.merchant_data.store_name}
                            </div>
                            <div className="muiDescripationInfo text-center">
                                {orderReturnSlip && orderReturnSlip.merchant_data && orderReturnSlip.merchant_data.address}
                                {orderReturnSlip && orderReturnSlip.merchant_data && ", " + orderReturnSlip.merchant_data.city}
                                {orderReturnSlip && orderReturnSlip.merchant_data && ", " + orderReturnSlip.merchant_data.state}
                                {orderReturnSlip && orderReturnSlip.merchant_data && ", " + orderReturnSlip.merchant_data.country}
                                {orderReturnSlip && orderReturnSlip.merchant_data && ", " + orderReturnSlip.merchant_data.postal_code}
                            </div>
                            <div className="muiDescripationInfo text-center">
                                Tel {orderReturnSlip && orderReturnSlip.merchant_data && orderReturnSlip.merchant_data.contact_number}
                            </div>
                        </div>

                        <div style={{ textAlign: "center" }}>
                            <img style={{ marginRight: "1vw" }} src={FaceBook} />
                            <img style={{ marginRight: "1vw" }} src={Twitter} />
                            <img style={{ marginRight: "1vw" }} src={Insta} />
                        </div>

                        <div style={{ textAlign: "center" }}>
                            <Barcode value={orderReturnSlip && orderReturnSlip.number} />
                        </div>
                    </div>
                </div>
           
        </div>
        </div>
    );
}
export default ReturnLabelSlip;