import React, { useEffect, useState } from 'react';
import Button from "../../../../../../components/button/button";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import SwipeableViews from "react-swipeable-views";
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { toast } from "react-toastify";
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import StorefrontIcon from '@material-ui/icons/Storefront';
import urls from "../../../../../../utils/services/apiUrl.json";
import BussinessCred from "../../InReviewTab/InReviewTabDriver/BussinessCredential/BussinessCredential";
import { getMethod, postMethodWithToken } from '../../../../../../utils/services/api';
import CourierSummary from '../../InReviewTab/CourierSummary/CourierSummary';

function OnHoldDriverDetail(props) {
    const params = useParams()
    const history = useHistory()
    const [onHoldDetail, setOnHoldDetail] = useState("")
    const [currTab, setCurrTab] = useState(0)

    const gotoBack = async () => {
        history.goBack()
    };
    useEffect(() => {
        getDriverOnHoldDetail()
    }, [])
    const getDriverOnHoldDetail = async () => {
        await getMethod(urls.getDriverDetail + params.id + "/?query_for=on-hold").then((response) => {
            if (response.status === 200 || response.status === 201) {
                setOnHoldDetail(response?.data?.ResponseData?.response)
            }
        })
    }
    function handleTabChange(event, newValue) {
        setCurrTab(newValue);
    }
    function handleChangeIndex(index) {
        setCurrTab(index);
    }
    const handleApproved = async () => {
        let body = ""
        await postMethodWithToken(urls.postDriver + params.id + "/?query_for=approved", body).then((response) => {
            if (response.status === 200 || response.status === 201) {
                history.push("/driver");
            }
        })
    }

    return (
        <div className={props.menu ? "w-100 screenLeftMargin" : "w-100 screenLeftMarginResponse"}>
            <div className="row mt-3 pl-3 ml-3 mr-3 mobileResponsive">
                <div onClick={() => gotoBack()} style={{ cursor: "pointer" }}>
                    <ArrowBackIcon /> <a style={{ color: "#4472C4" }} className="ml-2">Listings</a>
                </div>
            </div>
            <div className="row mt-2 ml-3 mr-3 mobileResponsive">
                <div className="col-7 d-flex">
                    <h1 className="heading-title">
                        <StorefrontIcon fontSize="large" /> On Hold Stores
                    </h1>
                </div>
                <div className="col-5 d-flex justify-content-end">
                    <Button className="btn btn-primary mr-3" name="Approve" onClick={() => handleApproved()}></Button>
                    {/* <Button className="btn btn-cancle" name="Delete"></Button> */}
                </div>
            </div>
            <div className="row mt-3 ml-3 mr-3 mobileResponsive">
                <div className="col-12">
                    <div className="reviewBox">
                        <div className="row">
                            <div className="col-4 mt-3 pl-5">
                                <h3 className="h6-label">Shop Details</h3>
                                <div>
                                    <p className="h2-label mt-2">
                                        ID: <span>{onHoldDetail?.id}</span>
                                    </p>
                                </div>
                            </div>
                            <div className="col-4 mt-3">
                                <h3 className="h6-label">Owner Details</h3>
                                <p className="h2-label mt-3">
                                    {onHoldDetail?.full_name}
                                </p>
                            </div>

                            <div className="col-4 mt-3 pr-5">
                                <h3 className="h6-label">Contact</h3>
                                <p className="mb-1 mt-3">
                                    <EmailIcon fontSize="small" style={{ color: "#808080", marginRight: "5Px" }} /> {onHoldDetail?.email}
                                </p>
                                <p>
                                    <PhoneIcon fontSize="small" style={{ color: "#808080", marginRight: "5Px" }} />
                                    {onHoldDetail?.phone_number}
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-4 mt-3">
                                <div className="ml-3 pl-3">
                                    <span>
                                        <p className="h5-label">Driver Details</p>
                                    </span>
                                    <p className="h6-label mb-0">
                                        <b>Driver Type</b>
                                        <span className="leadBox-details pl-4 ml-3">
                                            : {onHoldDetail?.partner_type?.name}
                                        </span>
                                    </p>
                                </div>
                            </div>
                            <div className="col-4 mt-3">
                                <p className="h5-label mt-4">Address</p>
                                <p className="leadBox-details">
                                    {onHoldDetail?.current_address}
                                </p>
                            </div>

                            <div className="col-4 mt-3">
                                <p className="h5-label mt-4">Manager Details</p>
                                <p className="mb-1 mt-3">
                                    {/* <b>{inReviewDetail.manager_detail[0].related_manager.name}</b> */}
                                </p>
                                <p className="mb-1">
                                    <EmailIcon fontSize="small" style={{ color: "#808080", marginRight: "5Px" }} />
                                    {onHoldDetail?.email}
                                </p>
                                <p>
                                    <PhoneIcon fontSize="small" style={{ color: "#808080", marginRight: "5Px" }} />
                                    {onHoldDetail?.phone_number}
                                </p>
                            </div>
                            <div className="w-100">
                                <Tabs
                                    value={currTab}
                                    onChange={handleTabChange}
                                    textColor="inherit"
                                    variant="standard"
                                >
                                    <Tab label="Driver Credentials" />
                                    <Tab label="Courier Summary" />
                                </Tabs>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="w-100">
                            <SwipeableViews index={currTab} onChangeIndex={(ind) => handleChangeIndex(ind)} enableMouseEvents={true}>
                                <div>
                                    <BussinessCred data={onHoldDetail} />
                                </div>
                                <div>
                                    <CourierSummary data={onHoldDetail} />
                                </div>

                            </SwipeableViews>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OnHoldDriverDetail
