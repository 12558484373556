import React from 'react'
import DefaultIamge from "../../../../../../../assets/images/ShopezDefult.svg"
import PDF from "../../../../../../../assets/images/pdfHolder.svg"

function BussinessCredential({ data }) {
    const checkFileExtension = (data) => {
        var fileExtension = data?.split(".").pop();
        return fileExtension === "pdf";
    };
    return (
        <div>
            <div className='BussinessBox mt-3'>
                <div className="row pt-3 pl-5">

                    {/* <img 
                            alt=""
                            style={{width: "200px", height: "200px"}}
                            src={data?.business_creditionals?.[0]?.file ? data?.business_creditionals?.[0]?.file : DefaultIamge }
                        /> */}
                    {checkFileExtension(data?.driver_image?.file) ? (
                        <a href={data?.driver_image?.file} target="_blank">
                            <img src={PDF} style={{ width: "200px", height: "200px" }} alt="" />
                        </a>
                    ) : (
                        <a href={data?.driver_image?.file} target="_blank">
                            <img src={data?.driver_image?.file ? data?.driver_image?.file : DefaultIamge} style={{ width: "200px", height: "200px" }} alt="" />
                        </a>
                    )}
                </div>
            </div>
        </div>
    )
}

export default BussinessCredential
