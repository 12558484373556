import React, { useState } from 'react';
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import SwipeableViews from "react-swipeable-views";
import InReviewTab from './Components/InReviewTab/InReviewTab';
import Approved from './Components/Approved/Approved';
import OnHold from './Components/OnHold/OnHold';

function Driver(props) {
    const [currTab, setCurrTab] = useState(0);

    const handleTabChange = (event, newValue) => {
        setCurrTab(newValue);
    }

    const handleChangeIndex = (index) => {
        setCurrTab(index);
    }
    const driverTabs = () => {
        return (
            <div className="row">
                <div style={{ width: "100%" }}>
                    <div className="col-12">
                        <Tabs
                            value={currTab}
                            onChange={handleTabChange}
                            textColor="inherit"
                            variant="standard"
                        >
                            <Tab
                                label="Shortlisted"
                            />
                            <Tab
                                label="Approved"
                            />
                            <Tab
                                label="On Hold"
                            />
                        </Tabs>
                    </div>
                </div>
                <div style={{ width: "100%" }}>
                    <div className="col-12">
                        <SwipeableViews
                            index={currTab}
                            onChangeIndex={handleChangeIndex}
                            enableMouseEvents={true}
                            className="p-0"
                        >
                            <div>{currTab === 0 && <InReviewTab />}</div>
                            <div>{currTab === 1 && <Approved />}</div>
                            <div>{currTab === 2 && <OnHold />}</div>
                        </SwipeableViews>
                    </div>
                </div>
            </div>
        )
    }
    return (
        <div className={props?.menu ? "w-100 screenLeftMargin mt-3" : "screenLeftMarginResponse w-100 mt-3"}>
            <div className="row ml-3 mr-3 mobileResponsive">
                <h1 className="heading-title col-6">Driver</h1>
            </div>
            <div className="row ml-3 mr-3 mt-3 mobileResponsive">
                <div className="col-12">
                    {driverTabs()}
                </div>
            </div>
        </div>
    )
}

export default Driver
