import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import urls from "../../../../../utils/services/apiUrl.json"
import { getMethod } from '../../../../../utils/services/api';
import SpinnerLoader from "../../../../../components/loader/spinnerLoader";
import LanguageIcon from '@material-ui/icons/Language';
import PhoneIcon from '@material-ui/icons/Phone';
import moment from 'moment/moment';
import Pagination from '@mui/material/Pagination';
import CollectionDB from "../../../../../assets/images/collection.png";

function InReviewTab() {
    const itemsPerPage = 20;
    const [loading, setLoading] = useState(false);
    const [listOfInReviewDriver, setListOfInReviewDriver] = useState([]);
    const history = useHistory();
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);

    useEffect(() => {
        getDriverInReviewList()
    }, [])
    const getDriverInReviewList = async () => {
        setLoading(true);
        await getMethod(urls.getDriverApi + "short-listed").then((response) => {
            if (response.status === 200 || response.status === 201) {
                setCount(Math.ceil(response.data.count / itemsPerPage))
                setListOfInReviewDriver(response.data.results.data);
                setLoading(false);
            }
        })
    }
    const createDate = (date) => {
        let now = new Date(date);
        var dateString = moment(now).format("DD MMM YYYY");
        return dateString;
    };
    const handlePageChange = async (event, value) => {
        setLoading(true);
        setPage(value)

        await getMethod(urls.getDriverApi + "short-listed&page=" + value).then((response) => {
            if (response.status === 200) {
                setCount(Math.ceil(response.data.count / itemsPerPage))
                setListOfInReviewDriver(response.data.results.data);
                setLoading(false);
            }
        });

        setLoading(false);
    }
    const inReviewDetails = async (item) => {
        history.push({
            pathname: `driver/inreview/${item.id}`,
            state: {
                vehicleImage: item?.vehicle_data?.regisration_certificate_photo
            }
        });
    };

    if (loading) {
        return <SpinnerLoader />;
    } else {
        return (
            <div>
                {count > 1 &&
                    <div className="shopPagination mt-3">
                        <Pagination
                            count={count}
                            page={page}
                            showFirstButton
                            showLastButton
                            onChange={(event, value) => handlePageChange(event, value)}
                        />
                    </div>
                }
                <div className="mt-3">
                    {listOfInReviewDriver && listOfInReviewDriver.length > 0 ? (
                        <>
                            <div className="row pt-3 pb-3 ml-0 mr-0">
                                <div className="col-3 f-600">App User</div>
                                <div className="col-3 f-600">Information</div>
                                <div className="col-3 f-600">Address</div>
                                <div className="col-3 f-600">Applied On</div>
                            </div>

                            {listOfInReviewDriver.length > 0 && listOfInReviewDriver.map((item, index) => {
                                return (
                                    <div className="row mt-3 pt-3 pb-3 ml-0 mr-0" style={{ background: "#ECECEC", cursor: "pointer" }} onClick={() => inReviewDetails(item)}>
                                        <div className="col-3 d-flex" style={{ alignItems: "center" }}>
                                            {item?.full_name}
                                        </div>
                                        <div className="col-3" style={{ alignItems: "center" }}>
                                            {item?.phone_number !== "" && item?.phone_number !== undefined && item?.phone_number !== null &&
                                                <div>
                                                    <PhoneIcon fontSize="small" style={{ color: "#808080", marginRight: "5Px", marginTop: "-1px" }} />
                                                    {item?.phone_number}
                                                </div>
                                            }

                                            {item?.email !== "" && item?.email !== undefined && item?.email !== null &&
                                                <div>
                                                    <LanguageIcon fontSize="small" style={{ color: "#808080", marginRight: "5Px", marginTop: "-1px" }} />
                                                    {item?.email}
                                                </div>
                                            }
                                        </div>
                                        <div className="col-3 d-flex" style={{ alignItems: "center" }}>
                                            {item?.current_address}
                                        </div>
                                        <div className="col-3 d-flex" style={{ alignItems: "center" }}>
                                            {item?.driver_create_on !== undefined ? createDate(item?.driver_create_on) : ""}
                                        </div>
                                    </div>
                                )
                            })}
                        </>
                    )
                        :
                        (
                            <div className='collection-img'>
                                <img src={CollectionDB} alt="" />
                            </div>
                        )


                    }
                </div>
                {count > 1 &&
                    <div className="shopPagination mt-3 mb-5">
                        <Pagination
                            count={count}
                            page={page}
                            showFirstButton
                            showLastButton
                            onChange={(event, value) => handlePageChange(event, value)}
                        />
                    </div>
                }
            </div>
        )
    }
}

export default InReviewTab
