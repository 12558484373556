import React from 'react';
import DefaultIamge from "../../../../../../assets/images/ShopezDefult.svg"
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

function CourierSummary({ data }) {
    const location = useLocation()
    console.log(location?.state)
    return (
        <div className="">
            <div className="FinancialBox mt-3 mb-5">
                <div className="row">
                    <div className="col-6">
                        <div className="h5-label mb-2 pt-3 pl-4">
                            Bank Details
                        </div>
                        <div className="row">
                            <div className="col-2 h6-label" style={{ marginLeft: "25px" }}>
                                Account No.
                            </div>
                            <div className="col-9 leadBox-details">
                                : {data?.account_detail?.account_no}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-2 h6-label" style={{ marginLeft: "25px" }}>
                                Account Type
                            </div>
                            <div className="col-9 leadBox-details">
                                : {data?.account_detail?.account_type}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-2 h6-label" style={{ marginLeft: "25px" }}>
                                Full Name
                            </div>
                            <div className="col-9 leadBox-details">
                                : {data?.account_detail?.full_name}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-2 h6-label" style={{ marginLeft: "25px" }}>
                                Institution No
                            </div>
                            <div className="col-9 leadBox-details">
                                : {data?.account_detail?.institution_no}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-2 h6-label" style={{ marginLeft: "25px" }}>
                                Transit No
                            </div>
                            <div className="col-9 leadBox-details">
                                : {data?.account_detail?.transit_no}
                            </div>

                        </div>

                    </div>
                    <div className="col-6">
                        <div className="h5-label mb-2 pt-3">Vehicle Information</div>
                        <div className="h6-label">
                            Vehicle Name
                            <span className="leadBox-details pl-5 ml-3">
                                : {data?.vehicle_data?.vehicle_type_name}
                            </span>
                        </div>
                        <div className="h6-label">
                            Vehicle Number
                            <span className="leadBox-details pl-5 ml-1">
                                :  {data?.vehicle_data?.vehicle_number !== null ? data?.vehicle_data?.vehicle_number : "-"}
                            </span>
                        </div>
                        {location?.state?.vehicleImage !== null &&
                            <>
                                <div className="h6-label mt-1">Vehicle Regisration Certificate</div>
                                <div>
                                    <a href={location?.state?.vehicleImage} target="_blank">
                                        <img src={location?.state?.vehicleImage} alt="" style={{ width: "200px", height: "200px", marginTop: "10px" }} />
                                    </a>
                                </div>
                            </>
                        }
                    </div>
                    <div className="col-6" style={{ marginTop: location?.state?.vehicleImage !== null && "-140px" }}>
                        <div className="h5-label mb-2 pt-3 pl-4">Driving License</div>
                        <div className="d-flex">
                            {data?.driver_licsence && data?.driver_licsence.length > 0 && data?.driver_licsence.map((item, index) => {
                                return (
                                    <div className="mb-5 pt-2 pl-4">
                                        <a href={item?.file} target="_blank">
                                            <img src={item?.file ? item?.file : DefaultIamge} style={{ width: "200px", height: "200px" }} alt="" />
                                        </a>
                                    </div>
                                )
                            })}

                        </div>
                    </div>
                    {/* <div className="col-6">
                        <div className="h5-label">vehicle Regisration Certificate</div>
                        <div>
                            <img src={location?.state?.vehicleImage} alt="" style={{ width: "200px", height: "200px", marginTop: "10px" }} />
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default CourierSummary
