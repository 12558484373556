import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyAo3x_rM0YhrrzStW8hGkRcKDGfjD8_bbM",
    authDomain: "shopez-a796b.firebaseapp.com",
    databaseURL: "https://shopez-a796b.firebaseio.com",
    projectId: "shopez-a796b",
    storageBucket: "shopez-a796b.appspot.com",
    messagingSenderId: "333802768565",
    appId: "1:333802768565:web:4298a565e98a09e5a456fc"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export {analytics};